import apiService from "./axiosInstance";

/*
 * POST - Create Customer, accepts customer data object
 */
export const createCustomerService = (data) => {
  return apiService.post("/customers", data);
};

/*
 * POST - Check preflight username availability
 */
export const preflightUsernameService = (data) => {
  return apiService.post("/customers/verify/username", data);
};

/*
 * POST - EventAPI V2, capturing user event data and posting to eventAPIV2, on snowflake
 */
export const eventApiWrapperV2Service = (eventData) => {
  return apiService.post("/adopter", eventData);
};

/*
 * POST - Discount Code Verification, accets discount code, and product code
 */
export const promoCodeService = (data) => {
  return apiService.post("/customers/verify/discount", data);
};

/*
 * POST - Authenticate User, accepts username, password, and gsid
 */
export const authenticateUser = (data) => {
  return apiService.post("/credentials", data);
};
