//Ethernet Product Code
export const ETHERNET_PRODUCT_CODE =
  "DL_Ethernet_1Gig_Internet_[1_Month_Recurring_69.00]";

//Ethernet Pricing
export const BOINGO_ETHERNET_AMOUNT = 69;
export const BOINGO_ETHERNET_DISCOUNTED_AMOUNT = 54;

//VenueID
export const AFMTHM_VENUE_ID = "4596416";

//promo code
export const AFMTHM_PROMO_CODE = "MTHM15";
