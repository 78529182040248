import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { getBraintreeAuthTokenFromDomain } from "../config/config";
import {
  BOINGO_ETHERNET_AMOUNT,
  BOINGO_ETHERNET_DISCOUNTED_AMOUNT,
} from "../assets/constant";

export default function ApplePayPayment({
  braintreeInstanceObject,
  setBraintreeInstanceObject,
  paymentType,
  setPaymentType,
}) {
  const [applePayInstanceDetails, setApplePayInstanceDetails] = useState(null);

  const getApplePaySession = () => {
    if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
      console.log("This device is not capable of making Apple Pay payments");
      console.log("This device does not support Apple Pay");
      return;
    }

    braintree.client.create(
      {
        authorization: getBraintreeAuthTokenFromDomain(window.location.host),
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }

        // Create Apple Pay instance
        braintree.applePay.create(
          {
            client: clientInstance,
          },
          (applePayErr, applePayInstance) => {
            if (applePayErr) {
              console.error("Error creating Apple Pay instance:", applePayErr);
              return;
            }

            console.log("apple pay instance: ", applePayInstance);
            setApplePayInstanceDetails(applePayInstance);
          }
        );
      }
    );
  };

  const performApplePayTransaction = async () => {
    if (!applePayInstanceDetails) {
      console.error("Apple Pay instance is not initialized.");
      return;
    }

    if (
      window.ApplePaySession &&
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()
    ) {
      console.log("Can make payments");

      //create payment request object to be sent to apple pay session
      var paymentRequest = applePayInstanceDetails.createPaymentRequest({
        total: {
          label: "Boingo",
          amount: BOINGO_ETHERNET_DISCOUNTED_AMOUNT,
        },
        requiredBillingContactFields: ["postalAddress"],
        requiredShippingContactFields: ["email", "phone", "postalAddress"],
      });

      var session = new window.ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = function (event) {
        applePayInstanceDetails.performValidation(
          {
            validationURL: event.validationURL,
            displayName: "Boingo",
          },
          function (validationErr, merchantSession) {
            console.log("merchat session: ", merchantSession);
            if (validationErr) {
              console.log("Apple pay failed to load onvalidatemerchant");
              session.abort();
              return;
            }
            session.completeMerchantValidation(merchantSession);
          }
        );
      };

      session.onpaymentauthorized = function (event) {
        applePayInstanceDetails.tokenize(
          {
            token: event.payment.token,
          },
          function (tokenizeErr, payload) {
            if (tokenizeErr) {
              console.log("tokenizeErr: ", tokenizeErr);
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
              return;
            }

            let applePayDetailsWithNonce = {
              paymentMethodNonce: payload.nonce,
              amount: BOINGO_ETHERNET_DISCOUNTED_AMOUNT,
              firstName: event.payment.billingContact.givenName,
              lastName: event.payment.billingContact.familyName,
              email: event.payment.shippingContact.emailAddress,
              phone: event.payment.shippingContact.phoneNumber,
              postal_code: event.payment.billingContact.postalCode,
            };

            let applePayDetailsNeededForBraintree = {
              nonce: payload.nonce,
              postal_code: event.payment.billingContact.postalCode,
              payment_type: "Apple Pay",
            };
            setPaymentType("applePay");
            //console.log("Apple pay data: ", applePayDetailsWithNonce);
            setBraintreeInstanceObject(applePayDetailsNeededForBraintree);
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          }
        );
      };
      session.begin();
    }

    //if users already have an Apple Pay card in their OS
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(
      applePayInstanceDetails.merchantIdentifier
    );
    promise.then(function (canMakePaymentsWithActiveCard) {
      if (canMakePaymentsWithActiveCard) {
        // Set up Apple Pay buttons
        // This device supports version 3 of Apple Pay.
      }
    });
  };

  useEffect(() => {
    getApplePaySession();
  }, []);

  return (
    <>
      {/* {JSON.stringify(braintreeInstanceObject)} */}
      <Box
        className="apple-pay-button apple-pay-button-black"
        onClick={performApplePayTransaction}
      ></Box>
    </>
  );
}
