// csrfService, is independent of axiosInstance, and use to fetch the csrf token for POST, PUT, PATCH, DELETE requests
import axios from "axios";
import { getEnterpriseUrl } from "../config/config";

const enterpriseUrl = getEnterpriseUrl(window.location.host);

const csrfApiService = axios.create({
  withCredentials: true,
  baseURL: enterpriseUrl,
});

export const fetchCsrfToken = async () => {
  const response = await csrfApiService.get("/csurf/token");
  return response.data.csrfToken;
};
