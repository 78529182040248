import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import "../src/assets/stylesheets/App.css";
import LandingPage from "./pages/LandingPage";
import SignupForm from "./pages/SignupForm";
import PaymentForm from "./pages/PaymentForm";
import Welcome from "./pages/Welcome";
import { ThemeProvider } from "@mui/material";
import boingoTheme from "./boingoTheme";
import StandardFaqs from "./pages/StandardFaqs";

// Wrapper component to handle venue ID
const VenueWrapper = ({ Component, ...rest }) => {
  const { venueId } = useParams();
  return <Component {...rest} venueId={venueId} />;
};

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [finalCustomerInfo, setFinalCustomerInfo] = useState("");
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    setQueryParams(params);
  }, []);

  // Create a props object for components
  const commonProps = {
    register,
    handleSubmit,
    watch,
    errors,
    finalCustomerInfo,
    setFinalCustomerInfo,
    queryParams,
  };

  return (
    <>
      <div className="App">
        <ThemeProvider theme={boingoTheme}>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/afmthm" replace />} />
              <Route
                path="/:venueId"
                element={
                  <VenueWrapper Component={LandingPage} {...commonProps} />
                }
              />
              <Route
                path="/:venueId/signup-form"
                element={
                  <VenueWrapper Component={SignupForm} {...commonProps} />
                }
              />
              <Route
                path="/:venueId/payment"
                element={
                  <VenueWrapper Component={PaymentForm} {...commonProps} />
                }
              />
              <Route
                path="/:venueId/ethernet-welcome"
                element={<VenueWrapper Component={Welcome} {...commonProps} />}
              />
              <Route
                path="/:venueId/faqs"
                element={
                  <VenueWrapper Component={StandardFaqs} {...commonProps} />
                }
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
