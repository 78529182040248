import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Input,
  Typography,
  useTheme,
} from "@mui/material";
import { promoCodeService } from "../services/apiServices";

//constants
import {
  ETHERNET_PRODUCT_CODE,
  AFMTHM_VENUE_ID,
  AFMTHM_PROMO_CODE,
} from "../assets/constant";

export default function PromoCode({ finalCustomerInfo, setFinalCustomerInfo }) {
  const theme = useTheme();
  const [promoCode, setPromoCode] = useState("");
  const [successPromoCodeInfo, setSuccessPromoCodeInfo] = useState("");
  const [errorPromoCodeResponse, setErrorPromoCodeResponse] = useState("");
  const hasAppliedPromo = useRef(false);

  useEffect(() => {
    // Check if the venue ID is 4596416 and automatically apply the promo code
    if (AFMTHM_VENUE_ID === "4596416" && !hasAppliedPromo.current) {
      setPromoCode(AFMTHM_PROMO_CODE);
      handlePromoCheck(AFMTHM_PROMO_CODE);
      hasAppliedPromo.current = true;
    }
  }, []);

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handlePromoCheck = (code = promoCode) => {
    setSuccessPromoCodeInfo("");
    setErrorPromoCodeResponse("");

    const promoCodeData = {
      discount_code: code,
      product_code: ETHERNET_PRODUCT_CODE,
      venue_id: AFMTHM_VENUE_ID,
    };

    promoCodeService(promoCodeData)
      .then((response) => {
        setSuccessPromoCodeInfo(response.data);

        //set state for finalCustomerInfo for ProductSection component to update DOM
        setFinalCustomerInfo({
          ...finalCustomerInfo,
          promo_code_success: response.data,
          discount_code: code,
        });
      })
      .catch((error) => {
        console.log(error);
        setErrorPromoCodeResponse(error.response);
        const updatedCustomerWithoutPromo = { ...finalCustomerInfo };
        delete updatedCustomerWithoutPromo.promo_code_success;
        delete updatedCustomerWithoutPromo.discount_code;
        setFinalCustomerInfo(updatedCustomerWithoutPromo);
      });
  };

  return (
    <>
      <Box className="promo-section" sx={{}}>
        <TextField
          className="form-input"
          required
          fullWidth
          type="text"
          margin="dense"
          variant="outlined"
          id="promo_code"
          label="Enter Promo Code"
          value={promoCode}
          onChange={handlePromoCodeChange}
          color="secondary"
          sx={{ width: "340px", marginRight: "10px" }}
        />
        <Button
          disabled={true}
          className="promo-submit"
          disableElevation
          variant="contained"
          type="submit"
          onClick={() => handlePromoCheck()}
          sx={{ width: "50px" }}
        >
          Apply
        </Button>
        <Box>
          {successPromoCodeInfo && (
            <Typography
              variant="body2"
              sx={{ fontFamily: "Figtree-Bold" }}
              color={theme.palette.secondary.main}
            >
              ${successPromoCodeInfo.discount_amount} off{" "}
              {successPromoCodeInfo.products[0].num_discount_months} months.
            </Typography>
          )}
          {errorPromoCodeResponse && (
            <Typography variant="body2" color={theme.palette.primary.main}>
              {errorPromoCodeResponse.data.message}
            </Typography>
          )}
        </Box>
        {/* <pre>{JSON.stringify(successPromoCodeInfo, null, 2)}</pre> */}
      </Box>
    </>
  );
}
